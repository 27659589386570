import React from 'react';

import tw, {styled} from 'twin.macro';
import {iState} from "@/state";
import {connect} from "react-redux";
import qs from 'query-string';

import {iState as iStateForm} from "@/state/reducers/form";
import {iState as iStateDigitalocean, setToken} from "@/state/reducers/digitalocean";

const Wrapper = styled.div(
	() => [tw`bg-primary-background flex sm:items-center justify-center h-screen px-5`]
);

const Message = styled.p(
	() => [tw`col-span-2 px-10 text-primary-text text-lg mt-2 text-center`]
);

interface Props extends React.HtmlHTMLAttributes<HTMLDivElement> {
	form: iStateForm;
	digitalocean: iStateDigitalocean;

	setToken: typeof setToken;
}

const Auth: React.FunctionComponent<Props> = (props: Props) => {
	const params = qs.parse(location.search);

	React.useEffect(() => {
		props.setToken({
			token    : `${params.token}`,
			expiresAt: Math.floor(Date.now() / 1000) + parseInt(`${params.expiresIn}`),
		});
	}, []);

	return (
		<Wrapper>
			<div className="max-w-lg">
				<Message>
					You may close this page now
				</Message>
			</div>
		</Wrapper>
	);
};


const mapStateToProps = (state: iState) => {
	return {
		digitalocean: state.digitalocean,
	};
};

const mapDispatchToProps = {setToken};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(Auth);
